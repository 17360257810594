<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <nav class="navbar navbar-expand-lg">
                <head-center></head-center>
            </nav>
        </div>
        <!--      内容          -->
        <div class="center-content">
            <!--    余额分类    -->
            <el-card v-if="topList.length">
                <el-row :gutter="20">
                    <el-col :span="6" v-for="(item, index) in topList" :key="index">
                        <div :class="`iq-card-body bg-${item.bgNum}`">
                            <div class="flex-space-between">
                                <div class="iq-card-mes">
                                    <p>{{ item.title }}</p>
                                    <p class="title">
                                        <countTo :startVal='0' :endVal="item.value" :decimals="item.fixedNum"
                                            :duration='1000'>
                                        </countTo>
                                    </p>
                                </div>
                                <div class="iq-card-icon">
                                    <img :src="item.imgIcon">
                                </div>
                            </div>
                            <div class="bottom-btn" @click="jumpPage(item.pagePath)" v-if="item.pagePath">
                                <span>充值</span><span>提现</span>
                            </div>
                            <div class="bottom-btn" v-else>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <!-- <el-row :gutter="20">
                    <el-col :span="6">
                        <div class="iq-card-body bg-5 ">
                            <div class="flex-space-between">
                                <div class="iq-card-mes left">
                                    <p>下级机构数</p>
                                    <p class="title">25</p>
                                </div>
                                <div class="bottom-img">
                                    <img src="../../../assets/img/index/n_icon01.png">
                                </div>
                            </div>
                            <div class="bottom-btn">
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="iq-card-body bg-5 ">
                            <div class="flex-space-between">
                                <div class="iq-card-mes left">
                                    <p>下级员工数</p>
                                    <p class="title">25</p>
                                </div>
                                <div class="bottom-img">
                                    <img src="../../../assets/img/index/n_icon02.png">
                                </div>
                            </div>
                            <div class="bottom-btn">
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="iq-card-body bg-5 ">
                            <div class="flex-space-between">
                                <div class="iq-card-mes left">
                                    <p>团队数</p>
                                    <p class="title">25</p>
                                </div>
                                <div class="bottom-img">
                                    <img src="../../../assets/img/index/n_icon03.png">
                                </div>
                            </div>
                            <div class="bottom-btn">
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="iq-card-body bg-5 ">
                            <div class="flex-space-between">
                                <div class="iq-card-mes left">
                                    <p>私域会员数</p>
                                    <p class="title">25</p>
                                </div>
                                <div class="bottom-img">
                                    <img src="../../../assets/img/index/n_icon04.png">
                                </div>
                            </div>
                            <div class="bottom-btn">
                            </div>
                        </div>
                    </el-col>
                </el-row> -->
            </el-card>
            <!--    快捷入口    -->
            <!-- <div class="center-title">
                <i class="el-icon-s-promotion"></i><span>快捷入口</span>
            </div> -->
            <el-card class="elCard" v-if="userBtnList.length || shopBtnList.length || statsBtnList.length">
                <div class="emtryDiv" v-if="userBtnList.length">
                    <p class="emtryTitle">用户管理</p>
                    <div class="emtryBox flex-start">
                        <div class="emtryItem flex-start" v-for="(item, index) in userBtnList" :key="index"
                            @click="jumpPage(item.pagePath)">
                            <div class="leftImg flex-center">
                                <img :src="item.imgIcon" />
                            </div>
                            <div class="rightContent">
                                <p class="btnTitle">{{ item.title }}</p>
                                <p class="btnDesc">{{ item.titleDesc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="emtryDiv" v-if="shopBtnList.length">
                    <p class="emtryTitle">店铺功能</p>
                    <div class="emtryBox flex-start flex-wrap">
                        <div class="emtryItem flex-start" v-for="(item, index) in shopBtnList" :key="index"
                            @click="jumpPage(item.pagePath)">
                            <div class="leftImg flex-center">
                                <img :src="item.imgIcon" />
                            </div>
                            <div class="rightContent">
                                <p class="btnTitle">{{ item.title }}</p>
                                <p class="btnDesc">{{ item.titleDesc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="emtryDiv" v-if="statsBtnList.length">
                    <p class="emtryTitle">数据统计</p>
                    <div class="emtryBox flex-start">
                        <div class="emtryItem flex-start" v-for="(item, index) in statsBtnList" :key="index"
                            @click="jumpPage(item.pagePath)">
                            <div class="leftImg flex-center">
                                <img :src="item.imgIcon" />
                            </div>
                            <div class="rightContent">
                                <p class="btnTitle">{{ item.title }}</p>
                                <p class="btnDesc">{{ item.titleDesc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </el-card>
            <div class="bg-img">
                <img v-if="topList.length == 0 && userBtnList.length == 0 && shopBtnList.length == 0 && statsBtnList.length == 0"
                    src="../../../assets/img/welcome01.png" />
            </div>
        </div>
    </div>
</template>

<script>
import { Index } from "../../../components/HospitalDomain/Index";
import countTo from 'vue-count-to';
export default {
    name: "index",
    components: {
        countTo
    },
    data() {
        var index = new Index(this.TokenClient, this.Services.Authorization);
        return {
            IndexDomain: index,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            IndexData: {
                balance: 0,
                userCount: 0,
                assetsCount: 0,
                assetsProfit: 0,
            },
            AllMenus: JSON.parse(sessionStorage.getItem('AllMenus')),
            showBalance: false,
            showUserCount: false,
            showAssetsCount: false,
            showAssetsProfit: false,
            topData: [{
                pagePath: '/Finance/Withdrawal',
                imgIcon: require("@/assets/img/index/m_icon01.png"),
                title: "当前余额(￥)",
                btnTag: '余额明细',
                value: 0,
                fixedNum: 2,
                bgNum: 1
            }, {
                pagePath: null,
                imgIcon: require("@/assets/img/index/m_icon02.png"),
                title: "员工账号数量",
                btnTag: '内部员工管理',
                value: 0,
                fixedNum: 0,
                bgNum: 2
            }, {
                pagePath: null,
                imgIcon: require("@/assets/img/index/m_icon03.png"),
                title: "分销员账号",
                btnTag: '私域会员管理',
                value: 0,
                fixedNum: 0,
                bgNum: 3
            }, {
                pagePath: null,
                imgIcon: require("@/assets/img/index/m_icon04.png"),
                title: "分销获利(￥)",
                btnTag: '分销明细',
                value: 0,
                fixedNum: 2,
                bgNum: 4
            }],
            topList: [],
            userBtnList: [],
            shopBtnList: [],
            statsBtnList: [],
            dataList: [
                {
                    pagePath: '/User/Staff',
                    imgIcon: require("@/assets/img/index/rk_icon01.png"),
                    title: "添加员工",
                    titleDesc: "Employee Add",
                    btnTag: "内部员工管理",
                    isShow: true,
                    userBtn: true
                },
                {
                    pagePath: '/User/Distributor',
                    imgIcon: require("@/assets/img/index/rk_icon02.png"),
                    title: this.ClientId == this.TotalClient?'添加会员':'添加团长',
                    titleDesc: this.ClientId == this.TotalClient?'User Add':'Group Leader Add',
                    btnTag: "私域会员管理",
                    isShow: true,
                    userBtn: true
                },
                {
                    pagePath: '/User/Staff',
                    imgIcon: require("@/assets/img/index/rk_icon03.png"),
                    title: "员工列表",
                    titleDesc: "Employee List",
                    btnTag: "内部员工管理",
                    isShow: true,
                    userBtn: true
                },
                {
                    pagePath: '/User/Distributor',
                    imgIcon: require("@/assets/img/index/rk_icon04.png"),
                    title: "团队列表",
                    titleDesc: "Team List",
                    btnTag: "私域会员管理",
                    isShow: true,
                    userBtn: true
                },
                {
                    pagePath: '/ShopMall/Settings',
                    imgIcon: require("@/assets/img/index/rk_icon05.png"),
                    title: "店铺设置",
                    titleDesc: "Store Settings",
                    btnTag: "店铺设置",
                    isShow: true,
                    shopBtn: true
                },
                {
                    pagePath: '/ShopMall/addCommodity',
                    imgIcon: require("@/assets/img/index/rk_icon06.png"),
                    title: "发布商品",
                    titleDesc: "Product Release",
                    btnTag: "普通商品",
                    isShow: true,
                    shopBtn: true
                },
                {
                    pagePath: '/ShopMall/addIntegralCommodity',
                    imgIcon: require("@/assets/img/index/rk_icon07.png"),
                    title: "发布积分换购",
                    titleDesc: "Point Merchandise Release",
                    btnTag: "积分商品",
                    isShow: true,
                    shopBtn: true
                },
                {
                    pagePath: '/ShopMall/addPrescription',
                    imgIcon: require("@/assets/img/index/rk_icon08.png"),
                    title: "发布处方药",
                    titleDesc: "Prescription Release",
                    btnTag: "处方药库",
                    isShow: true,
                    shopBtn: true
                },
                {
                    pagePath: '/ShopMall/Order/OwnOrder',
                    imgIcon: require("@/assets/img/index/rk_icon10.png"),
                    title: "商品订单",
                    titleDesc: "Commodity Order",
                    btnTag: "商品订单",
                    isShow: true,
                    shopBtn: true
                },
                {
                    pagePath: '/ShopMall/commodity',
                    imgIcon: require("@/assets/img/index/rk_icon09.png"),
                    title: "商品列表",
                    titleDesc: "Product List",
                    btnTag: "普通商品",
                    isShow: true,
                    shopBtn: true
                },
                {
                    pagePath: '/ShopMall/integralCommodity',
                    imgIcon: require("@/assets/img/index/rk_icon11.png"),
                    title: "积分商品列表",
                    titleDesc: "Points Item List",
                    btnTag: "积分商品",
                    isShow: true,
                    shopBtn: true
                },
                {
                    pagePath: '/ShopMall/prescription',
                    imgIcon: require("@/assets/img/index/rk_icon16.png"),
                    title: "处方药列表",
                    titleDesc: "Prescription Drug List",
                    btnTag: "处方药库",
                    isShow: true,
                    shopBtn: true
                },
                {
                    pagePath: '/Finance/BalanceDetail',
                    imgIcon: require("@/assets/img/index/rk_icon12.png"),
                    title: "余额账单",
                    titleDesc: "Balance Statement",
                    btnTag: "余额明细",
                    isShow: true,
                    statsBtn: true
                },
                {
                    pagePath: '/Finance/Profit',
                    imgIcon: require("@/assets/img/index/rk_icon13.png"),
                    title: "分销明细",
                    titleDesc: "Distribution Details",
                    btnTag: "分销明细",
                    isShow: true,
                    statsBtn: true
                },
                {
                    pagePath: '/STATS/Consumption',
                    imgIcon: require("@/assets/img/index/rk_icon14.png"),
                    title: "会员消费统计",
                    titleDesc: "Distribution Details",
                    btnTag: "会员消费统计",
                    isShow: true,
                    statsBtn: true
                },
                {
                    pagePath: '/STATS/Share',
                    imgIcon: require("@/assets/img/index/rk_icon15.png"),
                    title: "会员分红统计",
                    titleDesc: "Dividend Statistics",
                    btnTag: "会员分红获利",
                    isShow: true,
                    statsBtn: true
                }
            ]
        }
    },
    mounted() {
        this.getIndexData()
        this.InitContent();
    },
    methods: {
        InitContent() {
            var list = []
            $.each(this.AllMenus, function (index, item) {
                if (item.isEnable) {
                    list.push(item.name);
                    if (item.children) {
                        $.each(item.children, function (chileIndex, chile) {
                            list.push(chile.name);
                            if (chile.children) {
                                $.each(chile.children, function (chileIndex2, chile2) {
                                    list.push(chile2.name);
                                })
                            }
                        })
                    }
                }

            })
            this.topList = []
            this.topData.forEach((item) => {
                if (this.topList.toString().indexOf(item.btnTag) == -1) {
                    if (list.some(x => x === item.btnTag)) {
                        this.topList.push(item);
                    }
                }
            })
            this.userBtnList = []
            this.dataList.forEach((item) => {
                if (this.userBtnList.toString().indexOf(item.btnTag) == -1) {
                    if (list.some(x => x === item.btnTag && item.userBtn)) {
                        this.userBtnList.push(item);
                    }
                }
            })
            this.shopBtnList = []
            this.dataList.forEach((item) => {
                if (this.shopBtnList.toString().indexOf(item.btnTag) == -1) {
                    if (list.some(x => x === item.btnTag && item.shopBtn)) {
                        this.shopBtnList.push(item);
                    }
                }
            })
            this.statsBtnList = []
            this.dataList.forEach((item) => {
                if (this.statsBtnList.toString().indexOf(item.btnTag) == -1) {
                    if (list.some(x => x === item.btnTag && item.statsBtn)) {
                        this.statsBtnList.push(item);
                    }
                }
            })

        },
        jumpPage(path) {
            this.$router.push(path)
        },
        //获取顶部数据
        getIndexData() {
            var _this = this;
            _this.IndexDomain.IndexData(_this.organizationId,
                function (data) {
                    _this.IndexData = data.data;
                    _this.topData[0].value = _this.IndexData.balance
                    _this.topData[1].value = _this.IndexData.userCount
                    _this.topData[2].value = _this.IndexData.assetsCount
                    _this.topData[3].value = _this.IndexData.assetsProfit
                },
                function (error) { }
            )
        },
    }
}
</script>

<style scoped lang="scss">

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.iq-search-bar {
    flex: 1;
    padding: 0 15px;
}

.iq-search-bar .searchbox {
    width: 480px;
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #cbdbf7;
    line-height: 1.8;
}

.header {
    flex: 1;
    text-align: right;
    margin-right: 15px;
}

/*content*/
.center-content {
    height: calc(100vh - 43px);
    overflow-y: auto;
    padding: 80px 0 0;
}

.center-content .bg-img {
    width: 100%;
    height: calc(100vh - 160px);
}

.bg-img img {
    width: 100%;
    height: 100%
}

.bg-1 {
    background: #FFE9ED;
    border: 1px solid #FFE9ED;
}

.bg-2 {
    background: #E4EEFF;
    border: 1px solid #E4EEFF;
}

.bg-3 {
    background: #EEE5FF;
    border: 1px solid #EEE5FF;
}

.bg-4 {
    background: #D7F0E2;
    border: 1px solid #D7F0E2;
}

.bg-5 {
    background: #F5F5F9;
    border: 1px solid #F5F5F9;
}

.bg-1:hover {
    border: 1px solid #f7d4da;
}

.bg-2:hover {
    border: 1px solid #c9ddff;
}

.bg-3:hover {
    border: 1px solid #dac6ff;
}

.bg-4:hover {
    border: 1px solid #b3e0c7;
}

.bg-5:hover {
    border: 1px solid #e8e8f0;
}

.iq-card-body {
    padding: 23px;
    // margin-bottom: 18px;
    position: relative;
}

.iq-card-icon {
    width: 25%;
    height: 25%;
    margin-top: -20px;
}

.bottom-img {
    width: 25%;
    height: 25%;
    position: absolute;
    bottom: -1px;
    right: -1px;

    img {
        width: 100%;
        height: 100%;
    }
}

.iq-card-icon img {
    width: 100%;
    height: 100%;
}

.bottom-btn {
    height: 30px;
    line-height: 40px;

    span {
        color: #FF3D67;
        font-size: 16px;
        margin-right: 10px;
        cursor: pointer;
    }
}

.iq-card-mes {
    width: 75%;
    color: #333333;
}

.iq-card-mes .title {
    font-size: 34px;
    font-weight: bold;
    color: #333333;
}


.iq-card-mes p {
    font-size: 16px;
    color: #666666;
}

.iq-card-mes h3 {
    margin-top: 10px;
    font-size: 20px;
    font-weight: normal;
}


.bg-white {
    background: #FFFFFF;
}

.statistics {
    margin-top: 40px;
}

.iq-card-header {
    padding: 0 20px;
    min-height: 4px;
    border-bottom: 1px solid #eeeeee;
}

.iq-card-header span {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
}

.el-col-12 .iq-card-body {
    height: 400px;
}

@media (max-width: 1699px) {
    .iq-search-bar .searchbox {
        width: 350px;
    }
}

/* 快捷入口 */
.center-title {
    margin: 20px 0;
    font-size: 28px;
    color: #3D4A69;

    span {
        margin-left: 5px;
        letter-spacing: 2px;
    }
}

.title i {
    margin-right: 5px;
}

.el-card {
    border-radius: 25px;
}

.elCard {
    box-shadow: none;
    margin-top: 25px;

    .emtryDiv {
        margin: 10px 0;

        .emtryTitle {
            margin: 0 0 10px 0;
            color: #999999;
            font-size: 24px;
        }

        .emtryBox {
            width: 100%;
        }

        .emtryBox .emtryItem:hover {
            border: 1px solid #dadae675;
            box-shadow: 2px 3px 6px #eaeaf7;
        }

        .emtryBox .emtryItem {
            width: 24%;
            padding: 20px;
            height: 109px;
            border: 1px solid #DADAE6;
            border-radius: 12px;
            margin-bottom: 20px;
            cursor: pointer;
            margin-right: 1%;

            .leftImg {
                width: 25%;
                height: 100%;
                background: #F5F5F9;
                border-radius: 12px;

                img {
                    width: 40%;
                    height: 40%;
                }
            }

            .rightContent {
                margin-left: 10px;

                .btnTitle {
                    color: #000000;
                    font-size: 22px;
                    line-height: 1.3;
                    margin-top: 4px;
                }

                .btnDesc {
                    color: #999999;
                    font-size: 16px;
                }
            }
        }

        .emtryBox .emtryItem:nth-child(4) {
            margin-right: 0;
        }

        .emtryBox .emtryItem:nth-child(4n-3) {
            margin-left: 0;
        }
    }
}

/*PC*/
@media screen and (max-width:1024px) {

    // 头部
    .iq-card-mes {
        width: 100%;
    }

    .iq-card-mes .title {
        font-size: 15px;
    }

    .iq-card-icon {
        display: none;
    }

    // 用户管理
    .elCard .emtryDiv .emtryBox .emtryItem {
        .leftImg {
            display: none;
        }

        .rightContent {
            .btnTitle {
                font-size: 17px;
            }

            .btnDesc {
                font-size: 11px;
            }
        }
    }
}

@media screen and (min-width:1025px) and (max-width:1366px) {

    // 头部
    .iq-card-mes .title {
        font-size: 20px;
    }

    .iq-card-icon {}

    // 用户管理
    .elCard .emtryDiv .emtryBox .emtryItem {
        .leftImg {
            width: 25%;
            height: 60%;
        }

        .rightContent {
            .btnTitle {
                font-size: 18px;
            }

            .btnDesc {
                font-size: 11px;
            }
        }
    }
}

/*PC*/
@media screen and (min-width:1367px) and (max-width:1920px) {

    // 头部
    .iq-card-mes .title {
        font-size: 34px;
    }

    .iq-card-icon {}

    // 用户管理
    .elCard .emtryDiv .emtryBox .emtryItem {
        .leftImg {
            width: 25%;
            height: 100%;
        }

        .rightContent {
            .btnTitle {
                font-size: 22px;
            }

            .btnDesc {
                font-size: 16px;
            }
        }
    }
}

/*PC*/
@media screen and (min-width:1921px) {

    // 头部
    .iq-card-mes .title {
        font-size: 34px;
    }

    .iq-card-icon {}

    // 用户管理
    .elCard .emtryDiv .emtryBox .emtryItem {
        .leftImg {
            width: 25%;
            height: 100%;
        }

        .rightContent {
            .btnTitle {
                font-size: 22px;
            }

            .btnDesc {
                font-size: 16px;
            }
        }
    }
}
</style>
