let Index = function(tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

    /**
     * 获取首页账户余额，员工数量，分销员数量，分销获利
     * @param oranizationId
     * @param success
     * @param error
     * @constructor
     */
    this.IndexData = function(oranizationId, success, error) {
        var url = ServiceBaseAddress + '/api/Index/GetIndexData/'+ oranizationId
        TokenClient.Get(url, true, null, null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 机构首页---获取机构首页门诊数据(返回医生总人数DoctorNum,患者总人数PatientNum,审核医生总人数AuditDoctorNum)
     * @param {*} oranizationId 
     * @param {*} success 
     * @param {*} error 
     */
    this.GetDoctorReceptionIndex = function (success, error) {
        var url = ServiceBaseAddress + '/api/Index/GetDoctorReceptionIndex'
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 机构首页---获取机构余额月收入统计数据
     * @param success
     * @param error
     * @constructor
     */
    this.BalanceStatistics = function( success, error) {
        var url = ServiceBaseAddress + '/api/Index/BalanceIncomeStatistics'
        TokenClient.Get(url, true, null, null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 机构首页---获取指定机构员工分销/下级机构分销排行
     * @param success
     * @param error
     * @constructor
     */
    this.OrgUserTop = function( success, error) {
        var url = ServiceBaseAddress + '/api/Index/OrgainzationUserDistributorTop'
        TokenClient.Get(url, true, null, null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 获取指定机构指定年份下分销员月拉新统计
     * @param success
     * @param error
     * @constructor
     */
    this.UserMonthStatistics = function( success, error) {
        var url = ServiceBaseAddress + '/api/Index/UserMonthDistributorStatistics'
        TokenClient.Get(url, true, null, null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.GetPatientDiagnosesIndex = function (success, error) {
        var url = ServiceBaseAddress + "/api/Index/GetPatientDiagnosesIndex"
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
}
export {Index}
